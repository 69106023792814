import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyB_MBuaKqcfzxwo2TXSRBqMs4C5ZpJ6zSo",
  authDomain: "cpbl-fans-dev.firebaseapp.com",
  databaseURL: "https://cpbl-fans-dev.firebaseio.com",
  projectId: "cpbl-fans-dev",
  storageBucket: "cpbl-fans-dev.appspot.com",
  messagingSenderId: "866901845932",
  appId: "1:866901845932:web:bab962c1a5640ee73f7c09",
  measurementId: "G-RF61ZXHZR2"
}

firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()

export const authenticateAnonymously = () => {
  return firebase.auth().signInAnonymously()
}

export const getLatestNews = () => {
  return db
    .collection('news')
    .orderBy('publishedAt', 'desc')
    .limit(10)
    .get()
  }

export const updateNewsReadCount = ({collection, docId}) => {
  return db
    .collection(collection)
    .doc(docId)
    .update({
      read: firebase.firestore.FieldValue.increment(1)
    })
}
