import React, {useState, useEffect} from 'react'
import {View} from 'react-native'
import _ from 'lodash'

import * as dataService from '../services/data'
import NewsCard from '../components/newsCard'

const IndexView = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [articles, setArticles] = useState([])

  useEffect(() => {
    setLoading(true)
    dataService.getLatestNews()
      .then(querySnapshot => {
        const articleArray = []
        querySnapshot.forEach((doc) => {
          articleArray.push(doc.data())
        })
        setArticles(articleArray)
        setLoading(false)
      })
      .catch(e => {
        console.error(e)
        setLoading(false)
        setError('fetch failed')
      })
  }, [])

  if (loading) {
    return <p>loading..</p>
  }

  if (error !== '') {
    return <p>ERROR: {error}</p>
  }

  const onPressNewsItem = (url, title, firebaseDocumentKey) => {
    // CfTracking.logEvent('Index_clicked_news', {
    //   title,
    //   firebaseDocumentKey,
    // })

    // let collection = 'news'
    // if (this.locale === 'en-us') {
    //   collection = 'news-english'
    // }

    try {
      dataService.updateNewsReadCount({
        collection: 'news',
        docId: firebaseDocumentKey,
      })
    } catch (error) {
      console.log(error)
    }

    window.open(url, '_blank')
  }

  return (
    <View>
      {articles.map(article => (
        <NewsCard
          key={article.newsId}
          title={_.get(article, 'title', null)}
          newsSource={_.get(article, 'newsSource', '')}
          publishedAt={_.get(article, 'publishedAt', null)}
          image={_.get(article, 'urlToImage', null)}
          url={article.url}
          firebaseDocumentKey={article.newsId}
          isFeatureMode={false}
          read={_.get(article, 'read', 0)}
          onPressItem={onPressNewsItem}
          onLongPressItem={() => {}}
        />
      ))}
    </View>
  )
}

export default IndexView
