// import logo from './logo.svg';
import * as React from 'react'
import {View, Text} from 'react-native'

// import './App.css'
import IndexView from './containers/indexView'

function App() {
  return (
    <View style={{alignItems: 'center'}}>
      <View style={{maxWidth: 900}}>
        <IndexView />
      </View>
    </View>
  )
}

export default App;
